import * as React from "react";
import { navigate } from "@reach/router";
import "./Buttons.scss";
import { Link } from "gatsby";

interface IYellowButton
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  action?: any;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}
export const YellowButton = ({
  title,
  action,
  className,
  disabled,
  ...props
}: IYellowButton) => {
  return (
    <button
      {...props}
      disabled={disabled}
      onClick={action}
      className={`theshadow radius yellow-button d-block btn-clicked ${className} ${
        disabled ? "yellow-button__disabled" : ""
      }`}
    >
      {title}
    </button>
  );
};

export const GoHomeButton = () => (
  <Link to="/" className="go-to-home-button red-btn btn-clicked theshadow">
    Wróć na główną
  </Link>
);
