import React from "react";

export const SEO = ({ subtitle }: { subtitle?: string }) => (
  <>
    {subtitle === undefined} ? (<title>ThePolacyNFT</title>) : (
    <title>{subtitle} | ThePolacyNFT</title>)
    <meta
      name="description"
      content="Kolekcja 2137 Polaków NFT szarżuje po blockchainie Ethereum niczym elitarna polska skrzydlata Husaria, by siać strach, budować chwałę i odnieść spektakularne zwycięstwo!"
    />
    <meta
      property="og:title"
      content="Prestiżowa ekipa Polaków on-chain. Dołącz do niej :)"
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:description"
      content="Kolekcja 2137 Polaków NFT szarżuje po blockchainie Ethereum niczym elitarna polska skrzydlata Husaria, by siać strach, budować chwałę i odnieść spektakularne zwycięstwo!"
    />
    <meta property="og:url" content="/" />
    <meta property="og:site_name" content="ThePolacyNFT" />
    <meta
      property="og:image"
      content="https://thepolacy-web.vercel.app/static/gory-polacy-2-79e91085c8154cf4c5e1f74e55a9193d.png"
    />
    <meta
      property="og:image:secure_url"
      content="https://thepolacy-web.vercel.app/static/gory-polacy-2-79e91085c8154cf4c5e1f74e55a9193d.png"
    />
    <meta property="og:image:width" content="720" />
    <meta property="og:image:height" content="405" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:description"
      content="Kolekcja 2137 Polaków NFT szarżuje po blockchainie Ethereum niczym elitarna polska skrzydlata Husaria, by siać strach, budować chwałę i odnieść spektakularne zwycięstwo!"
    />
    <meta name="twitter:title" content="ThePolacyNFT" />
    <meta
      name="twitter:image"
      content="https://thepolacy-web.vercel.app/static/gory-polacy-2-79e91085c8154cf4c5e1f74e55a9193d.png"
    />
    <script>
      {`
        const cookie3Options = {"siteId":68,"additionalTracking":true,"cookielessEnabled":true}
        window._paq = window._paq || [];
        (function () {
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.async = true; 
            g.src = 'https://cdn.staging.cookie3.co/scripts/analytics/latest/cookie3.analytics.min.js';
            s.parentNode.insertBefore(g, s);
        })();
        `}
    </script>
    {/* <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-L6DYRM77ZE"
    ></script>
    <script type="application/js">
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-L6DYRM77ZE');
      `}
    </script> */}
  </>
);
