import * as React from "react";
import { Link, HeadFC } from "gatsby";
import { Loader } from "../components/Loader";
import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import { SEO } from "../components/SEO";

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
  textAlign: "center",
};

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <p className="text-center">
        <h3 className="text-xl my-2">
          Tak to sie dzieje jak się za duzo wypije :)
        </h3>
        <br />
        <h3 className="text-xl my-2">
          Husarzu dzielny i wspaniały, wracaj szybko do swoich! 🫡
        </h3>
        <br />
        <h2 className="text-xl my-2">
          A jak czujesz vibe zabawy... to zagraj sobie w gierkę 🎮
        </h2>
        <iframe
          className="mx-auto"
          width={"100%"}
          height="300px"
          src="https://gra.thepolacy.pl/"
        />
        <br />
        <GoHomeButton />
      </p>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <SEO subtitle="404..." />;
